import React from 'react';
import './Services.css';
import image1 from '../assets/top-view-hand-holding-smartphone.jpg';
import image2 from '../assets/shimmering-surface-luxurious-swimming-pool-surrounded-by-palm-trees-lounge-chairs.jpg';
import image3 from '../assets/top-view-hand-holding-smartphone.jpg';
import image4 from '../assets/winter-scene-with-people-snowboarding.jpg';
import image5 from '../assets/visa-application-form-smartphone.jpg';
import HeaderWithSquare from "../components/HeaderWithSquare";

const Services = () => {
  const services = [
    {
      title: "Viagens Personalizadas",
      description: "Roteiros sob medida para atender a todos os seus desejos e necessidades de viagem.",
      image: image1
    },
    {
      title: "Pacotes de Viagem",
      description: "Pacotes completos para destinos populares e exóticos, com tudo incluído para sua conveniência.",
      image: image2
    },
    {
      title: "Atendimento VIP",
      description: "Serviços exclusivos e suporte 24h para uma experiência de viagem sem igual.",
      image: image3
    },
    {
      title: "Reservas e Experiências",
      description: "Gerenciamos suas reservas de voos e hotéis, e oferecemos acesso exclusivo a atividades locais únicas.",
      image: image4
    },
    {
      title: "Assistência e Consultoria de Viagem",
      description: "Suporte completo e consultoria especializada para planejar e garantir uma viagem tranquila.",
      image: image5
    }
  ];

  return (
    <section className="services-section" id="services">
      <HeaderWithSquare title="Nossos Serviços" />
      <p>Desconecte-se e deixe-nos cuidar de tudo com roteiros personalizados e suporte exclusivo.</p>
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
