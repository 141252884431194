import React from 'react';
import './HeaderWithSquare.css'; // Arquivo de estilos

const HeaderWithSquare = ({ title }) => {
  return (
    <div id='#home' className="header-container">
      <div className="square"></div>
      <h1>{title}</h1>
    </div>
  );
};

export default HeaderWithSquare;
