import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Sobre from './components/Sobre';
import Services from './components/Services';
import Formulario from './components/Formulario';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  return (
    <div className="App">
      {/* Navbar com âncoras para as seções */}
      <Navbar />

      {/* Seções da página única */}
      <section id="home">
        <HeroSection />
      </section>

      <section id="sobre">
        <Sobre />
      </section>

      <section id="servicos">
        <Services />
      </section>

      <section id="contato">
        <Formulario />
      </section>

      {/* Rodapé */}
      <Footer />
    </div>
  );
};

export default App;