import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './Button.css'; // O CSS estará em um arquivo separado

const WhatsAppButton = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=48988703921"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <FaWhatsapp style={{ color: "#fff"}} className="whatsapp-icon" />
      Entre em Contato
    </a>
  );
};

export default WhatsAppButton;
