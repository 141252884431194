import React from 'react';
import './HeroSection.css';
import Button from '../components/Button';

const HeroSection = () => {
  return (
    <section className="hero-section" id="home">
      <div className="hero-content">
        <h1>Desconecte-se da rotina.</h1>
        <h2>Conecte-se com o mundo.</h2>
        < Button />
      </div>
    </section>
  );
};

export default HeroSection;
