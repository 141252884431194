import React, { useState, useEffect } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Navbar.css";
import Logo from "../assets/Logo_png.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navbarScrolled, setNavbarScrolled] = useState(false);

  // Função para abrir/fechar o menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Função para verificar a rolagem da página
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavbarScrolled(true);
    } else {
      setNavbarScrolled(false);
    }
  };

  // useEffect para adicionar/remover o event listener de scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${navbarScrolled ? "scrolled" : ""}`}>
      <nav className="nav_container">
        {/* Logo e Nome da Agência */}
        <div className="nav__left">
          <img src={Logo} alt="Desconecte - Agência de Viagens e Turismo" className="nav__logo" />
          <span className="nav__brand">Desconecte - Agência de Viagens e Turismo</span>
        </div>

        {/* Menu de Navegação */}
        <div className={`nav__menu ${isOpen ? "show-menu" : ""}`} id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <a href="#home" className="nav__link" onClick={toggleMenu}>
                Início
              </a>
            </li>
            <li className="nav__item">
              <a href="#sobre" className="nav__link" onClick={toggleMenu}>
                Sobre
              </a>
            </li>
            <li className="nav__item">
              <a href="#servicos" className="nav__link" onClick={toggleMenu}>
                Serviços
              </a>
            </li>
            <li className="nav__item">
              <a href="#contato" className="nav__link" onClick={toggleMenu}>
                Contato
              </a>
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        {/* Ícone de Menu Móvel */}
        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
