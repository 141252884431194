import React from 'react';
import './Sobre.css';
import Image1 from "../assets/sobre.png";
import HeaderWithSquare from "../components/HeaderWithSquare";
import { FaWhatsapp } from 'react-icons/fa';

function Sobre() {
  return (
    <section className="sobre">
      <div className='text'>
        <HeaderWithSquare title="Viaje do Seu Jeito" />
        <h3>Viagens personalizadas para experiências únicas</h3>
        <p>
          Na Desconecte, criamos viagens personalizadas que ajudam você a fugir da rotina e explorar o mundo de forma autêntica. Com nosso atendimento VIP e suporte 24 horas, cada detalhe da sua viagem será pensado para que você aproveite ao máximo, sem preocupações. Planeje sua próxima aventura conosco e descubra novas maneiras de viver o mundo!
        </p>
        <a
          href="https://api.whatsapp.com/send?phone=48988703921"
          target="_blank"
          rel="noopener noreferrer"
          className="button">
          <FaWhatsapp  className="icon"/>
          Entre em Contato
        </a>
      </div>
      
      <div className="imagens">
        <img src={Image1} alt="Paisagem Exótica com Viajante Explorando" />
      </div>
    </section>
  );
}

export default Sobre;
