import React from 'react';
import './Footer.css';
import Logo from '../assets/Logo_png.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo">
          <img src={Logo} alt="Desconecte - Agência de Viagens e Turismo" />
        </div>
        <p className="footer-text">Desconecte - Agência de Viagens e Turismo. © 2024 Todos os direitos reservados.</p>
        <p>CNPJ: 57.100.405/0001-19</p>
      </div>
    </footer>
  );
}

export default Footer;


